import {
  TCoordinate,
  TGeoJsonFeature,
  TGeoJsonFeatureItem,
} from '@/components/molecules/Map/types/MapPolygon';
import { parsePolygonCoordinates } from '@/components/molecules/Map/utils/parsePolygonCoordinates';
import colors from '@/styles/themes/brand/colors';

const colorsMap: { [key: string]: string } = {
  ADM: colors.primary,
  AAM: colors.success,
  WRM: colors.secondary,
};

const calculateEdges = (coords: Array<TCoordinate>) => {
  return coords.reduce(
    (extremes, { lat, lng }) => ({
      minLat: Math.min(lat, extremes.minLat),
      maxLat: Math.max(lat, extremes.maxLat),
      minLng: Math.min(lng, extremes.minLng),
      maxLng: Math.max(lng, extremes.maxLng),
    }),
    { minLat: Infinity, maxLat: -Infinity, minLng: Infinity, maxLng: -Infinity }
  );
};
export const parseDistrictsToPolygons = (
  districtsCluster: Array<TGeoJsonFeature>,
  generateBoundingBoxCenter: boolean = false
) => {
  if (!Array.isArray(districtsCluster) || !districtsCluster.length) {
    console.error('Invalid districtsCluster: It must be a non-empty array.');
    return {};
  }

  let minLatOuter = Infinity,
    maxLatOuter = -Infinity,
    minLngOuter = Infinity,
    maxLngOuter = -Infinity;

  let boundingBoxCenter;

  const data = districtsCluster.reduce(
    (acc, district) => {
      const { nameEnglish, geometry, municipalityCode } = district;

      // Validate geometry and coordinates
      if (!geometry || !geometry.rings || !Array.isArray(geometry.rings)) {
        console.warn(`Invalid geometry for district: ${nameEnglish}`);
        return acc; // Skip invalid district
      }

      const triangleCoords = parsePolygonCoordinates(geometry.rings);

      // Validate parsed coordinates
      if (
        !Array.isArray(triangleCoords) ||
        triangleCoords.some(
          coord =>
            !Array.isArray(coord) ||
            coord.length !== 2 ||
            !isValidCoordinate(coord)
        )
      ) {
        console.warn(`Invalid coordinates for district: ${nameEnglish}`);
        return acc; // Skip invalid district
      }

      // Calculate the bounding box edges
      const { minLat, maxLat, minLng, maxLng } = calculateEdges(triangleCoords);

      // Initialize district data if not already
      if (!acc[nameEnglish]) {
        acc[nameEnglish] = {
          triangleCoords: [],
          strokeColor: colors.mapBorderColor,
          fillColor: colorsMap[municipalityCode],
          attributes: district,
        };
      }

      acc[nameEnglish].triangleCoords.push(...triangleCoords);

      // Update bounding box if required
      if (generateBoundingBoxCenter) {
        if (minLat < minLatOuter) minLatOuter = minLat;
        if (maxLat > maxLatOuter) maxLatOuter = maxLat;
        if (minLng < minLngOuter) minLngOuter = minLng;
        if (maxLng > maxLngOuter) maxLngOuter = maxLng;
      }

      return acc;
    },
    {} as Record<string, TGeoJsonFeatureItem>
  );

  if (generateBoundingBoxCenter) {
    boundingBoxCenter = {
      lat: (minLatOuter + maxLatOuter) / 2,
      lng: (minLngOuter + maxLngOuter) / 2,
    };
  }

  return { data, boundingBoxCenter };
};

// Centralized coordinate validation function
const isValidCoordinate = (coord: any): boolean => {
  // Check that each coordinate is a number and within valid latitude and longitude ranges
  const [lat, lng] = coord;
  return (
    typeof lat === 'number' &&
    typeof lng === 'number' &&
    lat >= -90 &&
    lat <= 90 &&
    lng >= -180 &&
    lng <= 180
  );
};
